<template>
	<div class="login_box">
		<div class="position_box">
			<span class="title">上海援青项目信息化管理平台</span>
			<div class="box">
				<span class="box_title">手机验证登录</span>
				<div class="input_box">
					<img src="~@/assets/img/login/手机.svg" />
					<input type="text" placeholder="请输入手机号" v-model="phone" />
				</div>
				<div class="input_box_line">
					<div class="input_box">
						<img src="~@/assets/img/login/锁.svg" />
						<input type="text" placeholder="请输入验证码" v-model="code" />
					</div>
					<span class="get_code" :class="{ disabled: cd }" @click="getCode"
						>获取验证码{{ cd ? `(${cd})` : "" }}</span
					>
				</div>
				<span class="login_btn" @click="browser_login">立即登录</span>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref } from "vue";
import api from "@/api/methods/index.js";
import { Message } from "element-ui";
import myPublic from "@/utils/public.js";
import store from "@/vuex/index.js";
import router from "@/router/index.js";
import util_login from "@/utils/login.js";

const phone = ref("");
const code = ref("");
const cd = ref(0);

const getCode = async () => {
	if (cd.value) return;
	if (!phone.value) {
		Message({
			message: "请先输入手机号",
			type: "warning",
		});
		return;
	}

	if (!myPublic.validateMobileNumber(phone.value)) {
		Message({
			message: "输入手机号不合法，请重新输入",
			type: "warning",
		});
		return;
	}

	const msg = await api.login.getCode({ phone: phone.value });
	if (msg.data.state == 1) {
		Message({
			message: "验证码发送成功，请注意查收",
			type: "success",
		});

		cd.value = 60;
		let timer = setInterval(() => {
			cd.value--;
			if (cd.value <= 0) {
				clearInterval(timer);
			}
		}, 1000);
	}
};

const browser_login = async () => {
	if (!phone.value || !code.value) {
		Message({
			message: "请输入手机号和验证码",
			type: "warning",
		});
		return;
	}

	const msg = await api.login.loginBrowser({
		phone: phone.value,
		code: code.value,
	});
	if (msg.data.state == 1) {
		Message({ message: "登录成功", type: "success" });
		store.commit("user/SETTOKEN", msg.data.data.token);
		const user_info = myPublic.init_power(msg.data.data.info);
		store.commit("user/SETCURRENTUSER", user_info);
		store.commit("user/SETCORP", msg.data.data.corp);
		api.project.getConfig({ token: msg.data.data.token }).then((res) => {
			if (res.data.state == 1) {
				store.commit("user/SETPROJECTEDIT", res.data.data);
			}
		});
		store.state.user.firstGo++;
		router.replace({ path: "/pc/index/all" });

		// 保存token
		localStorage.setItem("token", msg.data.data.token);
	}
};

// 页面刷新时，自动获取token
// localStorage.setItem("token", "698d51a19d8a121ce581499d7b701668");
// const login = async () => {
// 	const token = localStorage.getItem("token");
// 	if (token) {
// 		await util_login.loginByToken(token);
// 		router.replace({ path: "/pc/index/all" });
// 	}
// };
// login();
</script>

<style lang="less" scoped>
.login_box {
	width: 100vw;
	height: 100vh;
	background: url("~@/assets/img/login/背景pc.png") no-repeat;
	background-size: 100% 100%;

	.position_box {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		display: flex;
		flex-direction: column;
		align-items: center;

		.title {
			font-size: 50px;
			color: white;
			margin-bottom: 70px;
			display: inline-block;
			white-space: nowrap;
		}
	}

	.box {
		width: 400px;
		height: 400px;
		background-color: white;
		overflow: hidden;
		border-radius: 30px;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 30px;
		box-sizing: border-box;

		.box_title {
			font-size: 20px;
			font-weight: bold;
			margin-bottom: 20px;
		}

		.input_box_line {
			width: 100%;
			display: flex;
			flex: 1;

			.get_code {
				height: 46px;
				width: 170px;
				background-color: #f49c3a;
				box-sizing: border-box;
				border-radius: 6px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: 20px;
				margin-left: 10px;
				color: white;
				cursor: pointer;

				&.disabled {
					cursor: not-allowed;
					background-color: rgb(193, 193, 193);
				}
			}
		}

		.input_box {
			display: flex;
			align-items: center;
			background-color: #f3f4f4;
			width: 100%;
			height: 46px;
			border-radius: 6px;
			margin-bottom: 20px;
			padding: 0 14px;
			box-sizing: border-box;

			img {
				height: 20px;
				width: 20px;
			}

			input {
				flex: 1;
				background-color: transparent;
				margin-left: 14px;
				height: 40px;
			}
		}

		.login_btn {
			width: 100%;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #3dc184;
			color: white;
			border-radius: 6px;
			cursor: pointer;
		}
	}
}
</style>
